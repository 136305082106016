import { Component, Input } from '@angular/core';
import { CurrentMeetService } from '../../shared/services/current-meet.service';
import { CurrentTimezoneModel } from '../../shared/models/current-timezone.model';
import { Observable } from 'rxjs';
import { AsyncPipe, DatePipe } from '@angular/common';
import { DateTime } from 'luxon';
import { AliveDatePipe } from '../../shared/pipes/alive-date.pipe';

@Component({
  selector: 'app-event-date',
  standalone: true,
  imports: [DatePipe, AsyncPipe, AliveDatePipe],
  templateUrl: './event-date.component.html',
  styleUrl: './event-date.component.scss',
})
export class EventDateComponent {
  @Input() meetDates!: string[];
  @Input() date!: string;
  currentTimezone$!: Observable<CurrentTimezoneModel | null>;

  constructor(private currentMeetService: CurrentMeetService) {
    this.currentTimezone$ = this.currentMeetService.updateCurrentTimezone;
  }

  protected readonly DateTime = DateTime;
}
